var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"background":"#f8f9fb"},attrs:{"id":"inspire"}},[_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('app-header'),_c('v-card',{staticClass:"elevation-3 py-5"},[_c('v-toolbar',{attrs:{"color":"white blue--text","flat":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"c-blue-text"},[_vm._v("Create Password")])])],1),_c('v-card-text',{staticClass:"px-10"},[_c('v-form',{ref:"createPassword",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Password","name":"new-password","prepend-icon":"mdi-lock","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"rules":[
                    _vm.required('Password'),
                    _vm.minLength('password', 8),
                    _vm.lowerCaseFormat(),
                    _vm.upperCaseFormat(),
                    _vm.digitFormat(),
                    _vm.specialCharFormat(),
                  ]},on:{"keyup":function($event){return _vm.runValidation()},"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"label":"Confirm password","name":"confirmPassword1","prepend-icon":"mdi-lock","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":[
                    _vm.required('password'),
                    _vm.confirmPassword('password', _vm.password),
                  ]},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.confirmPassword1),callback:function ($$v) {_vm.confirmPassword1=$$v},expression:"confirmPassword1"}}),_c('v-card-actions',{staticClass:"px-10"},[_c('v-spacer'),_c('v-btn',{staticClass:"my-2",attrs:{"block":"","large":"","color":"secondary","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.createPassword}},[_vm._v("Submit Password")])],1)],1)],1)],1)],1)],1)],1)],1),_c('app-bottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }